<template>
    <main-layout>
        <template #content>
            <header-content :header-info="headerInfo">
                <header-content-addition
                    ref="addition"
                    :addition="headerInfo"
                    @clearQuery="onClearQuery"
                />
            </header-content>
            <loading-overlay v-if="dataLoading" />
            <div
                v-if="credits.length > 0"
                class="wrapper"
            >
                <div class="credits-container__table">
                    <div class="credits-container__table-header">
                        <div class="credits-container__table-header__item credits-container__table-header__item-date">
                            Completion date
                        </div>
                        <div class="credits-container__table-header__item credits-container__table-header__item-reference">
                            ID
                        </div>
                        <div class="credits-container__table-header__item credits-container__table-header__item-title">
                            Title
                        </div>
                        <div class="credits-container__table-header__item credits-container__table-header__item-credit">
                            Total
                        </div>
                    </div>

                    <div class="credits-container__table-list">
                        <div
                            v-for="(credit,index) in credits"
                            :key="index"
                            class="credits-container__table-list__item"
                        >
                            <div class="credits-container__table-list__item-col credits-container__table-list__item-col__date">
                                <span class="credits-container__table-list__item-col__index">{{ index + 1 }}.</span>
                                <img
                                    src="@/assets/img/svg/arrow-double-gray.svg"
                                    alt="Due"
                                    width="16px"
                                    height="16px"
                                    class="card_body__detail-img"
                                    style="margin-right: 10px"
                                >
                                <span v-if="credit.created_at">
                                    {{ credit.created_at | moment_short_text }}
                                </span>
                                <span v-else>Invalid date</span>
                            </div>

                            <div
                                class="credits-container__table-list__item-col credits-container__table-list__item-col__reference"
                                @click.prevent="redirectToTheId(credit.orderid)"
                            >
                                <img
                                    src="@/assets/img/svg/order-id.svg"
                                    alt="Order id"
                                    width="20px"
                                    height="20px"
                                    class="card_body__detail-img"
                                    style="margin-right: 10px"
                                >
                                {{ credit.orderid }}
                            </div>

                            <div class="credits-container__table-list__item-col credits-container__table-list__item-col__title">
                                <span>&laquo;{{ credit.title || '-' }}&raquo;</span>
                            </div>

                            <div class="credits-container__table-list__item-col credits-container__table-list__item-col__credit">
                                <span v-if="credit.sw_total > 0">{{ credit.sw_total | money }}</span>
                                <span v-else>-</span>
                            </div>
                        </div>
                    </div>
                    <!--                    <div class="credits-container__btn">-->
                    <!--                        <custom-button-->
                    <!--                            v-if="lastPage !== 1"-->
                    <!--                            default-->
                    <!--                            class="button btn-base_colored sort-button"-->
                    <!--                        >-->
                    <!--                            Load more-->
                    <!--                        </custom-button>-->
                    <!--                    </div>-->
                </div>
            </div>
            <!--            <NoResult-->
            <!--                v-if="!credits.length"-->
            <!--                type="credits"-->
            <!--            />-->
            <transition name="fade">
                <div
                    v-if="!credits.length"
                    class="earnings-not-found"
                >
                    <not-found
                        text="You do not have any earnings yet."
                        desc="Complete any order to get earning."
                    />
                </div>
            </transition>
        </template>
    </main-layout>
</template>

<script>

// import { mapGetters } from 'vuex'

import MainLayout from '@/layouts/MainLayout';
// import NoResult from '@/components/common/NoResult'
import Api from '@/helpers/api/index.js'
import filtersMixin from '@/mixins/filtersMixin.js'
import HeaderContent from '@/components/HeaderContent';
import HeaderContentAddition from '@/components/HeaderContentAddition';
import LoadingOverlay from '@/components/LoadingOverlay';
import NotFound from '@/components/NotFound'

export default {
    components: {
        MainLayout,
        // NoResult,
        NotFound,
        HeaderContent,
        HeaderContentAddition,
        LoadingOverlay
    },
    metaInfo: {
        title: 'Earnings'
    },
    mixins: [
        filtersMixin
    ],
    data() {
        return {
            credits: [],
            dataLoading: false,
            rowsPerPage: 10,
            page: 1,
            lastPage: null,
            balance: '',
            headerInfo: {
                title: 'Earnings',
                className: 'header-content__credits',
                btn_text: 'Request payout',
                btn_link: 'credits',
                report: true,
                steps: [
                    {
                        title: 'Home',
                        url: '/'
                    },
                    {
                        title: 'Dashboard',
                        url: '/cp/account'
                    }
                ]
            }
        }
    },
    // computed: {
    //     ...mapGetters('writer', [
    //         'getterAccountBalance'
    //     ])
    // },
    async created() {
        await this.getData()
        if (this.$route.query.preset === 'request') {
            this.$refs.addition.onBtnClick()
        }
    },
    methods: {
        // loadMore() {
        //     this.page += 1
        //     this.rowsPerPage = this.page * 10
        //     this.getData()
        // },
        async getData() {
            this.dataLoading = true;
            const vm = this
            await Api.get('/api/order/list-completed')
                .then((response) => {
                    this.credits = response.data.data
                    vm.headerInfo = { ...vm.headerInfo, counter: filtersMixin.filters.money(response.data.total) }
                    // this.lastPage = response.data.last_page
                })
                .then(() => {
                    vm.dataLoading = false;
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        redirectToTheId(id) {
            this.$router.push({
                name: 'details',
                params: { id }
            })
        },
        onClearQuery() {
            this.$router.replace({ query: null });
        }
    }
}
</script>

<style lang="scss">
.credits-container {
    width: 1200px;
    margin: 75px auto 0;
    &__btn {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    &__table {
        margin: 30px 0 50px;
        &-header {
            padding: 0 20px;
            background: inherit;
            display: flex;
            &__item {
                color: #afb2b9;
                font-size: 14px;
                font-weight: 400;
                padding: 20px 0;
                &-date {
                    flex-basis: 25%;
                    padding-left: 40px;
                }
                &-reference {
                    flex-basis: 25%;
                }
                &-title {
                    flex-basis: 25%;
                }
                &-credit {
                    flex-basis: 25%;
                    text-align: end;
                }
            }
        }
        &-list {
            display: flex;
            flex-direction: column;
            &__item {
                padding: 30px 25px;
                display: flex;
                margin-top: 15px;
                box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
                background-color: $white;
                &:first-child {
                    margin-top: 0;
                }
                &-col {
                    font-size: 14px;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                    width: 25%;
                    &__index {
                        font-size: 14px;
                        margin-right: 20px;
                        color: #afb2b9;
                    }
                    &__date {
                        flex-basis: 25%;
                        text-align: center;
                    }
                    &__reference {
                        flex-basis: 25%;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                    &__title {
                        flex-basis: 25%;
                        font-style: italic;
                        text-overflow: ellipsis;
                        font-weight: 500;
                    }
                    &__title span {
                        overflow: hidden;
                    }
                    &__credit {
                        flex-basis: 25%;
                        color: #181818;
                        font-weight: 500;
                        font-size: 20px;
                        justify-content: right;
                    }
                    &__sum {
                        position: relative;
                        margin-top: 15px;
                        span {
                            justify-content: right;
                            padding: 10px 15px;
                            background: #1161E0;
                            border-radius: 4px;
                            font-size: 20px;
                            line-height: 24px;
                            color: #fff;
                            font-weight: bold;
                            position: absolute;
                            right: 3%;
                        }
                    }
                }
            }
        }
    }
}

.earnings-not-found {
    margin: 100px 0;
}

@media all and (max-width: 1200px) {
    .credits-container {
        width: calc(100% - 40px);
        margin: 85px 20px 0;
    }
}
@media all and (max-width: 996px) {
    .credits-container__table-header {
        display: none;
    }

    .credits-container__table-list__item {
        padding: 15px 20px;
        &-col {
            position: relative;
            margin-top: 25px;
            margin-right: 10px;
            padding-right: 0;
            &:last-child {
                margin-right: 0;
            }
            &::before {
                position: absolute;
                color: #afb2b9;
                font-size: 12px;
                font-weight: 400;
                top: -20px;
            }
            &__date {
                padding-left: 0;
                &::before {
                    content: 'Completion date';
                    left: 28px;
                }
            }
            &__title {
                &::before {
                    content: 'Title';
                    font-style: normal;
                }
            }
            &__reference {
                &::before {
                    content: 'ID';
                }
            }
            &__client-name {
                text-align: left;
                &::before {
                    content: 'Client';
                }
            }
            &__credit {
                &::before {
                    content: 'Total';
                }
            }
        }
    }

    .credits-container__table-list__item-col__sum span {
        right: 2%;
    }
}

@media all and (max-width: 940px) {
    .bc-credits {
        .wrapper {
            h1.page-title.breadcrumbs-item {
                align-items: flex-start;
            }
        }
    }
    .credits-container__table {
        margin: 30px 0 50px;
        &-list__item {
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 20px;

            &-col {
                margin: 20px 0;
                flex-basis: 50%;
                &:last-child{
                    flex-basis: 10%;
                }
                &__credit {
                    justify-content: left;
                }
            }
        }
    }

}

@media all and (max-width: 568px) {
    .credits-container__table {
        &-list__item {
            &-col {
                flex-basis: 100%;
            }
        }
    }
}

</style>
